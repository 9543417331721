@import "../../../variables.scss";

.CreatorComponent {
    position: fixed;
    left: 0;
    top: 100px;
    bottom: 100px;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    .CreatorComponentBox {
        color: #fff;
        margin: 0 auto;
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding: 20px;
        background-color: rgba(27, 63, 124, 0.3);
        border-radius: 10px;
        z-index: 100000000;
        max-width: 1400px;
        overflow: hidden;
        width: 90%;

        @media all and (max-width: 900px) {
          flex-direction: column;
          gap: 20px;
          align-items: center;
          width: 500px;
        }

        @media all and (max-width: 900px) and (max-height: 900px) {
          width: 50%;
        }

        @media all and (min-width: 900px) and (max-height: 750px) {
          width: fit-content;
        }

        > .camera {
            overflow: hidden;
            position: relative;
            background-color: rgba(27, 63, 124, 0.4);
            width: 1000px;
            color: #fff;
            margin: 0 auto;
            border-radius: 10px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            aspect-ratio: 16 / 9;
            z-index: 100000;

            @media all and (max-width: 900px) {
              max-width: 400px;
            }

            @media all and (min-width: 900px) and (max-height: 750px) {
              max-width: 600px;
            }

            @media all and (min-width: 900px) and (max-height: 700px) {
              max-width: 500px;
            }

            @media all and (min-width: 900px) and (max-height: 650px) {
              max-width: 400px;
            }

            .LoaderComponent {
                background-color: #000 !important;
            }

            .controls {
                position: absolute;
                z-index: 100;
                bottom: 10px;
                left: 0;
                right: 0;
                height: 85px;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;

                > span {
                    flex: 1;
                }

                > i {
                    height: 65px;
                    width: 65px;
                    background-color: rgba($comitas-blue-900, 1);
                    border-radius: 10px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 34px;
                    cursor: pointer;
                    margin: 0 10px;
                }

                > i:hover {
                    background-color: rgba($comitas-blue-900, 0.6);
                }
            }

            .settings {
                top: 100px;
                background-color: #fff;
                box-sizing: border-box;
                bottom: 100px;
                right: 0;
                overflow-y: auto;
                color: #000;
                z-index: 10000;
                display: flex;
                flex-direction: column;
                align-items: center;

                .select {
                    border-radius: 6px;
                    border: 1px solid #aaa;
                    width: 350px;
                    position: relative;
                    * {
                        cursor: pointer;
                    }
                    > span {
                        cursor: pointer;
                        padding: 10px 15px;
                        display: block;
                        width: 300px;
                        overflow: hidden;
                        position: relative;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        > b {
                            display: block;
                            width: 250px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            font-weight: normal;
                        }
                        i {
                            position: absolute;
                            right: 0px;
                            opacity: 0.5;
                            top: 0;
                            bottom: 0;
                            display: flex;
                            align-items: center;
                            font-size: 20px;
                            justify-content: center;
                        }
                    }

                    .options {
                        position: absolute;
                        top: 100%;
                        margin-top: 10px;
                        z-index: 100000;
                        background-color: #fff;
                        padding: 5px;
                        border-radius: 5px;
                        box-shadow: 0 0 50px 10px rgba(0, 0, 0, 0.2);
                        outline: 4px solid #aaa;
                        width: 100%;
                        box-sizing: border-box;
                        max-height: 300px;
                        overflow-y: scroll;
                        > div {
                            padding: 10px 15px;
                            border-top: 1px solid #ddd;
                            cursor: pointer;
                            &:first-child {
                                border-top: none;
                            }
                            &:hover {
                                background-color: #eee;
                            }
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            max-width: 100%;
                        }
                    }
                }

                .settings-header {
                  width: 100%;
                  // margin-bottom: 20px;
                  display: flex;
                  // flex-direction: column;
                  align-items: center;
                  // justify-content: center;
                  background-color: $comitas-blue-900;
                  height: 50px;

                  h2 {
                    font-size: 17px;
                    margin: 0;
                    color: #fff;
                    padding-left: 30px;
                    font-weight: 600;
                  }

                  i {
                    position: absolute;
                    right: 27px;
                    top: 11px;
                    font-size: 15px;
                    color: #fff;
                    cursor: pointer;

                    &:hover {
                      color: #a7a4a4;
                    }
                  }
                }

                .settings-body {
                  position: absolute;
                  top: 50px;
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                }

                p {
                  font-weight: bold;
                  font-size: 16px;
                  padding: 10px;
                  margin: 0;
                  opacity: 0.5;
                  width: 350px;
                  margin-top: 10px;
                }
            }

            > video {
                width: 100%;
                height: 100%;
                z-index: -1;
                position: absolute;
                transition: all 2s cubic-bezier(0, 0.82, 0.165, 1);
                border-radius: 10px;
                overflow: hidden;
                opacity: 0;
                object-position: center;
                background-color: transparent;
                object-fit: contain;
            }

            &.active {
                > video {
                    opacity: 1;
                }
            }
        }

        > .form {
            padding: 0px 25px;
            margin: 0;
            font-weight: 200;
            display: flex;
            flex-direction: column;
            justify-content: center;
            min-width: 400px;
            text-align: center;
            max-width: 400px;
            box-sizing: border-box;

            h3 {
                font-size: 28px;
                margin: 0;
                color: #fff;
                padding: 0;
                margin-bottom: 10px;
            }

            p {
                font-size: 17px;
                margin: 0;
                color: #fff;
                font-weight: 400;
                line-height: 1.5;
                padding: 0;
                margin-bottom: 20px;
            }

            .creator-buttons {
              @media all and (max-width: 900px) {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                gap: 20px;
              }

              @media all and (max-width: 900px) and (max-height: 900px) {
                flex-direction: column;
                justify-content: center;
                align-items: center;

                button {
                  width: 50%;
                }
              }
            }

            button {
                background-color: rgb(53, 53, 53);
                color: #fff;
                border: 0;
                border-radius: 7px;
                padding: 17px 40px;
                letter-spacing: 1px;
                font-size: 16px;
                font-weight: 600;
                margin-top: 20px;
                cursor: pointer;
                transition: all 0.2s ease-in-out;
                box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
                margin: 0 10px 10px;

                &.important {
                    background-color: rgba($comitas-blue-900, 0.848);
                    color: #fff;
                }

                &.dial {
                    background-color: rgba($comitas-green-800, 0.848);
                    color: #fff;

                    &:disabled {
                      background-color: rgba($comitas-green-800, 0.5);
                      color: #fff;
                      cursor: not-allowed;
                    }
                }

                &.dial:hover {
                    background-color: rgba($comitas-green-900, 0.848);
                    color: #fff;
                }

                &.localDial:disabled {
                    background-color: rgba(53, 53, 53, 0.5);
                    color: #fff;
                    cursor: not-allowed;
                }

                &:hover {
                    background-color: #000;
                    color: #fff;
                }
            }
        }
    }

    .add {
        &:hover {
            background: #aaa !important;
            cursor: pointer;
        }
    }
}
