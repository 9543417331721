.DirectoryComponent {
  h2 {
    padding: 0 20px;
    margin: 20px 0 0;
    font-size: 20px;
    display: flex;
    flex-direction: row;

    b {
      margin-left: auto;
      font-size: 14px;
      background: rgba(0, 0, 0, 0.1);
      padding: 5px 7px;
      border-radius: 5px;
    }
  }

  .Success {
    margin: 0;
  }
}
