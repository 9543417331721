@import './variables.scss';
@media (max-width: 800px) {
    .noMobile {
        display: none !important;
    }
    .CreatorComponentBox .camera {
        display: none !important;
    }
    .DishVideoComponent .settings {
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        width: 100%;
    }
}

@media (max-width: 900px) and (max-height: 800px) {
    .CreatorComponentBox .camera {
        display: none !important;
    }
}
.Modal {
    background: #fff;
    position: fixed;
    top: 100px;
    left: 0;
    bottom: 100px;
    width: 400px;
    z-index: 10000000;
    // margin: 30px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    // box-shadow: 0px 0px 210px 40px rgba(0, 0, 0, 0.2);
    animation: slideIn 1s cubic-bezier(0.075, 0.82, 0.165, 1);
    // phone version
    @media (max-width: 600px) {
        padding: 0px !important;
        margin: 0 !important;
        overflow-y: auto;
        overflow-x: hidden;
        width: 100%;
    }
    .Modal {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        margin: 0;
        animation: slideInLeft 1s cubic-bezier(0.075, 0.82, 0.165, 1);
    }

    &.Right {
        right: 0;
        left: auto;
        animation: slideInLeft 1s cubic-bezier(0.075, 0.82, 0.165, 1);
    }
}

.DishMutedWidget {
  position: absolute;
  bottom: 10px;
  right: 10px;
  z-index: 1000;
}

.Information {
    line-height: 25px;
    font-size: 13px;
    font-weight: 500;
    margin-left: 12px;
    border-left: 2px solid #00000023;
    padding-left: 33px;
    margin-top: 30px;

    b {
      font-family: 'Rotis', sans-serif;
      font-weight: 900;
      font-size: 15px;
      letter-spacing: 1px;
      color: rgba(227, 28, 37, 1);
    }
}

.Form {
    padding: 25px 25px 0;
    flex: 1;
    overflow-y: auto;
    flex-direction: column;
    flex-wrap: nowrap;
    display: flex;
    // dont remove height divs childrens
    flex-grow: 1;
    flex-shrink: 0;

    p {
        margin-top: 15px;
        font-weight: bold;
        opacity: 0.5;
    }

    .Meet-Info {
      background-color: rgb(214, 200, 200);
      border-radius: 16px;
      padding: 15px;
      text-align: justify;
      font-size: 12px;
      box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.2);

      p {
        margin: 0;
        font-weight: 400;
      }
    }
}

@keyframes slideIn {
    0% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(0);
    }
}

@keyframes slideInLeft {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(0);
    }
}

.Header {
    font-size: 17px;
    height: 50px;
    padding: 0 15px 0 20px;
    color: white;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    font-weight: 600;
    // box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    background-color: $comitas-blue-900;
    position: relative;

    b {
        margin-right: auto;
    }

    .add {
        padding: 10px 15px;
        font-size: 15px;
        margin-right: 10px;
        cursor: pointer;
        border-radius: 5px;
        background-color: #aaaaaa30;
        margin-left: auto;
        font-weight: bold;
        color: rgb(47, 64, 255);

        &:hover {
            background-color: #aaaaaa50;
        }
    }

    .jumpstep {
      font-size: 14px;
      text-align: center;
      width: 105px;
    }

    > i {
        font-size: 15px;
        margin-right: 0px;
        opacity: 1;
        cursor: pointer;
        border-radius: 100px;
        width: 50px;
        height: 50px;
        line-height: 20px;
        text-align: center;
        // color: rgba(0, 0, 0, 0.4);
        color: white;
        transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1);
        padding-top: 10px;

        &.last {
            margin-left: auto;
        }

        &:hover {
            // outline: 10px solid rgba(0, 0, 0, 0.1);
            // transform: rotate(180deg);
            color: rgb(167, 164, 164);
        }

        &.la-angle-left {
            margin-left: -15px;

            &:hover {
                transform: rotate(0deg);
            }
        }
    }
}

.Link {
    position: relative;
    padding: 20px 20px 20px 70px;
    border-top: 1px solid #ccc;
    font-size: 17px;
    cursor: pointer;
    &.last {
        border-bottom: 1px solid #ccc;
    }

    i:first-child {
        position: absolute;
        top: 50%;
        left: 20px;
        font-size: 30px;
        height: 40px;
        width: 40px;
        line-height: 40px;
        margin-top: -20px;
        opacity: 0.2;
    }

    i:last-child {
        position: absolute;
        top: 50%;
        right: 20px;
        font-size: 30px;
        height: 40px;
        width: 40px;
        line-height: 40px;
        margin-top: -20px;
        opacity: 0.2;
    }
}

.Search {
    position: relative;

    i {
        position: absolute;
        top: 50%;
        left: 20px;
        font-size: 30px;
        height: 40px;
        width: 40px;
        line-height: 40px;
        margin-top: -20px;
        opacity: 0.2;
        text-align: center;
    }

    input {
        width: 100%;
        padding: 20px 0px 20px 70px;
        border-radius: 0;
        outline: none;
        box-sizing: border-box;
        border-top: 1px solid #ccc;
        border-left: none;
        border-right: none;
        border-bottom: 1px solid #ccc;
        font-size: 20px;
    }
}

.Checkbox {
    position: relative;
    margin: 10px 0 0;
    border-radius: 5px;
    width: 100%;
    padding: 20px 0px 20px 70px;
    outline: none;
    box-sizing: border-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    color: #000;
    width: 350px;
    background-color: rgba(0, 0, 0, 0.058);
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: rgba(0, 0, 0, 0.594);
    background-color: #fff;
    cursor: pointer;

    &:hover {
        background-color: rgba(255, 255, 255, 0.058);
    }

    &.active {
        i {
            color: green;
            background-color: rgba(4, 255, 50, 0.195);
        }
    }

    a {
        text-decoration: underline;
        font-weight: bold;
        color: #0c3a7c;
    }

    i {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 20px;
        font-size: 15px;
        height: 25px;
        color: rgb(0, 0, 0);
        width: 25px;
        line-height: 25px;
        margin-top: 0;
        opacity: 1;
        text-align: center;
        border-radius: 5px;
        box-sizing: border-box;
        border: 1px solid rgba(0, 0, 0, 0.2);
        background-color: rgba(0, 0, 0, 0.09);

        &.la-times {
            color: red;
        }
    }

    input {
    }
}

.Input {
    position: relative;
    margin: 10px 0 0;

    i {
      position: absolute;
      top: 50%;
      left: 15px;
      font-size: 20px;
      height: 40px;
      color: rgb(0, 0, 0);
      width: 40px;
      text-align: center;
      line-height: 40px;
      margin-top: -20px;
      opacity: 0.2;

      &.show-password {
        cursor: pointer;
        left: auto;
        right: 15px;
      }

    }

    select {
        border-radius: 5px;
        width: 100%;
        padding: 15px 0px 15px 70px;
        outline: none;
        box-sizing: border-box;
        border: 1px solid rgba(0, 0, 0, 0.2);
        color: rgb(0, 0, 0);
        max-width: 350px;
        background-color: #fff;
        font-size: 16px;
        text-indent: 0;
        display: block;

        -webkit-appearance: none;

        white-space: normal;

        * {
            white-space: normal;
            padding: 0;
            margin: 0;
        }

        &:focus {
            border-color: rgb(0, 0, 0);
            outline: 4px solid rgba(0, 0, 0, 0.086);
        }

        &:first-child {
            padding-left: 20px;
        }
    }

    textarea,
    input {
        border-radius: 5px;
        width: 100%;
        padding: 15px 0px 15px 70px;
        outline: none;
        box-sizing: border-box;
        border: 1px solid rgba(0, 0, 0, 0.2);
        color: rgb(0, 0, 0);
        max-width: 350px;
        resize: none;
        background-color: #fff;
        font-size: 15px;

        &:focus {
            border-color: rgb(0, 0, 0);
            outline: 4px solid rgba(0, 0, 0, 0.086);
        }

        &:first-child {
            padding-left: 20px;
        }
    }

    textarea {
        height: 200px;
    }

    *:disabled {
        background-color: rgba(0, 0, 0, 0.2);
        color: rgba(0, 0, 0, 0.7);
        opacity: 1;
        cursor: not-allowed;
    }
}

.rec-info {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #e59c12;
  margin-top: 10px;
  font-style: italic;

  i {
    font-size: 25px;
    margin-right: 10px;
  }
}

.Logotype {
    width: 25rem;
    margin: 45px;
    position: fixed;
    z-index: 100000;
    top: 0;
    left: 0;
    // version phone
    @media (max-width: 600px) {
        display: none;
    }
}

.version {
  .Logotype {
    margin: 0px 10px;
    width: 250px;
    position: relative;
  }
}

.Alternative {
    text-align: center;
    margin-top: 20px;
    color: rgba(0, 0, 0, 0.3);

    &.link {
        text-decoration: underline;
        margin-top: 10px;

        &:hover {
            color: rgba(0, 0, 0, 0.5);
            cursor: pointer;
        }
    }
}

.Space {
    height: 25px;
    min-height: 25px;
}

.Submit-position {
    position: sticky;
    bottom: 0px;
    min-height: 100px;
    margin-top: auto;
    background-color: #fff;
    margin-left: -25px;
    margin-right: -25px;
    box-shadow: 0px -5px 50px 25px rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    padding-bottom: 15px;
}

.Submit {
    border-radius: 5px;
    padding: 15px 0px 15px 0px;
    outline: none;
    text-align: center;
    font-size: 15px;
    font-weight: bold;
    margin-top: 20px;
    text-transform: uppercase;
    letter-spacing: 1px;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid rgba(0, 0, 0, 0.144);
    color: rgb(255, 255, 255);
    max-width: 350px;
    background-color: rgb(12, 59, 125);
    font-size: 16px;
    scale: 0.8;
    cursor: pointer;

    &:hover {
        background-color: rgba(12, 59, 125, 0.848);
        opacity: 1;
    }

    &.secondary {
        opacity: 0.5;
        background-color: rgba(0, 0, 0, 0.144);
        color: rgba(0, 0, 0, 1);
        margin-top: 10px;
        scale: 0.8;

        &:hover {
            opacity: 1;
        }
    }

    &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }
}

.Button {
    padding: 20px 20px;
    font-size: 20px;
    align-items: center;
    margin: 20px;
    color: #fff;
    text-align: center;
    font-weight: bold;
    background-color: rgb(48, 183, 53);
    border-radius: 10px;
}

.AccordionLine {
    border-top: 1px solid #ccc;
    margin-left: -25px;
    margin-right: -25px;
}

.Pair {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0px;
    width: 350px;

    > p {
        flex: 1;

        &:first-child {
            margin-right: 5px;
        }

        &:last-child {
            margin-left: 5px;
        }
    }

    .Input {
        flex: 1;

        &:first-child {
            margin-right: 5px;
        }

        &:last-child {
            margin-left: 5px;
        }

        input {
            width: 100%;
        }
    }
}

.Success {
    background: #f5fff7;
    box-shadow: 0 0 20px 13px rgba(0, 0, 0, 0.1);
    padding: 15px 25px;
    display: flex;
    color: rgb(48, 183, 53);
    font-weight: bold;
    margin-left: -25px;
    margin-right: -25px;
    margin-top: -10px;
    margin-bottom: 10px;
    align-items: center;
    display: flex;
    flex-direction: row;
    box-sizing: border-box;

    &.float {
        margin: 0 0px;
        border-radius: 5px;
        max-width: 350px;
        /* font-size: 13px; */
    }

    > p {
        padding: 0;
        margin: 0;
    }

    > i {
        font-size: 30px;
        color: rgb(48, 183, 53);
        margin-right: 20px;
    }
}

.Empty {
    background: #fffbf5;
    box-shadow: 0 0 20px 13px rgba(0, 0, 0, 0.1);
    padding: 15px 25px 15px 20px;
    display: flex;
    color: rgb(183, 113, 48);
    font-weight: bold;

    margin-bottom: 10px;
    align-items: center;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #ccc;

    &.Column {
        flex-direction: column;
    }

    > p {
        padding: 0;
        margin: 0;
    }

    > i {
        font-size: 30px;
        color: rgb(183, 100, 48);
        margin-right: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        text-align: center;
    }
}

.Day {
    > .title {
        font-weight: bold;
        padding: 20px 20px 0;
        font-size: 18px;
        text-transform: capitalize;
    }
    .List {
        overflow: visible;
        padding-top: 10px;
        padding-bottom: 10px;
    }
}

.List {
    flex: 1;
    overflow-y: scroll;
    margin: 0;
    padding: 20px;

    &.compact {
        min-height: 0;
        flex: none;
    }

    > li {
        display: flex;
        list-style-type: none;
        text-align: left;
        padding: 10px 10px 10px 10px;
        border-radius: 5px;
        box-shadow: 0px 0px 51px rgba(0, 0, 0, 0.2);
        margin-bottom: 10px;
        align-items: center;

        &.clicable {
            cursor: pointer;

            &:hover {
                outline: 4px solid rgba(0, 0, 0, 0.086);
            }
        }

        &.disabled {
          opacity: 0.6;
          cursor: not-allowed;

          &:hover {
            outline: none;
          }
        }


        > i {
            width: 40px;
            height: 40px;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 25px;
            color: rgb(40, 90, 255);
            margin-left: auto;
            margin-left: 10px;
            cursor: pointer;

            &.square,
            &.la-check {
                margin-left: 0;
                padding-left: 0;
                background-color: rgb(195, 195, 195);
                margin-right: 20px;

                &.selected {
                    color: rgb(255, 255, 255);
                    background-color: rgb(40, 90, 255);
                }
            }

            &.disabled {
              cursor: not-allowed;
            }

            &.square {
                background-color: rgba(195, 195, 195, 0.4);
            }
            &.la-video,
            &.la-pen,
            &.la-user-injured {
                color: rgb(255, 255, 255);
                margin-left: 0;
                padding-left: 0;
                background-color: rgb(40, 90, 255);
                margin-right: 20px;
            }

            &.la-video {
                margin-right: 15px;
                margin-left: -10px;
                background-color: rgb(36, 37, 43);
            }

            &.la-trash-alt {
                color: rgb(255, 40, 40);
            }
        }

        .user {
            flex: 1;

            .name {
                font-size: 16px;
                font-weight: bold;
            }

            .document {
                font-size: 15px;
                opacity: 1;
                color: #aaa;
                display: flex;
                flex-direction: row;
                align-items: center;

                b {
                    font-weight: bold;
                    margin-left: 10px;
                    padding: 3px 5px;
                    background-color: #ddd;
                    font-size: 10px;
                    border-radius: 5px;
                    color: #000000d3;
                }
            }
        }
    }
}

.History {
    z-index: 1100000;
    margin-left: -25px;
    margin-right: -25px;
    animation: historyIn 1s cubic-bezier(0.075, 0.82, 0.165, 1);
    margin-top: 15px;

    @keyframes historyIn {
        0% {
            opacity: 0;
            transform: translateY(60%);
        }

        100% {
            transform: translateY(0);
        }
    }

    .HistoryItem {
        margin: 10px 20px;
        overflow: hidden;
        background: #ffffff;
        padding: 0 15px 15px;
        border-radius: 10px;
        box-shadow: 0px 0px 51px 5px rgba(0, 0, 0, 0.4);
        animation: historyIn 1s cubic-bezier(0.075, 0.82, 0.165, 1);

        .Submit {
            &.delete {
                margin-top: 10px;
                padding: 10px 0;
                font-size: 14px;
                background-color: rgba(208, 82, 82, 0.85);
            }
        }
    }

    .Submit {
        margin-top: 25px;
    }
}

.Internet {
    border-radius: 5px;
    padding: 15px 0px 15px 0px;
    outline: none;
    text-align: center;
    font-size: 15px;
    font-weight: bold;
    margin-top: 20px;
    text-transform: uppercase;
    letter-spacing: 1px;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid rgba(0, 0, 0, 0.144);
    color: rgb(255, 255, 255);
    max-width: 350px;
    background-color: rgba(52, 76, 254, 0.848);
    font-size: 16px;
    cursor: pointer;

    &:hover {
        background-color: rgba(80, 98, 231, 0.848);
        opacity: 1;
    }

    &.secondary {
        opacity: 0.5;
        background-color: rgba(0, 0, 0, 0.144);
        color: rgba(0, 0, 0, 1);

        &:hover {
            opacity: 1;
        }
    }
}
.Toastify__toast-container--top-right {
    top: 100px !important;
    z-index: 1000000000000;
}
.Toastify__progress-bar-theme--light {
    background: rgba(80, 98, 231, 0.848) !important;
}

.Section {
    font-weight: bold;
    padding: 20px 20px 10px 0;
    font-size: 19px;
    opacity: 0.5;
}

.Tabs {
    display: flex;
    flex-direction: row;

    padding: 5px;
    .Tab {
        background-color: #ddd;
        border-radius: 5px;
        padding: 10px;
        text-align: center;
        flex: 1;
        margin: 5px;
        cursor: pointer;

        &.active {
            background-color: rgba(52, 76, 254, 0.848);
            color: #fff;
            box-shadow: 0px 0px 51px 5px rgba(0, 0, 0, 0.2);
        }
    }
}

.topbar {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 1000000;
    display: flex;
    flex-direction: row;
    .icon {
        height: 40px;
        width: 40px;
        display: flex;
        font-size: 25px;
        align-items: center;
        justify-content: center;
    }
}

.Blocked {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.556);
    backdrop-filter: blur(10px) grayscale(100%);
}

.btn-sm {
  font-size: 24px !important;
  width: 42px !important;
  height: 42px !important;
}

.btn-md {
  font-size: 28px !important;
  width: 50px !important;
  height: 50px !important;
}

.btn-lg {
  font-size: 32px !important;
  width: 64px !important;
  height: 64px !important;
}

.btn-xl {
  font-size: 36px !important;
  width: 72px !important;
  height: 72px !important;
}

.btn-xxl {
  font-size: 40px !important;
  width: 80px !important;
  height: 80px !important;
}
