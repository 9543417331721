.AccordionComponent {
    margin-left: -25px;
    margin-right: -25px;
    padding: 0;

    > .title {
        border-top: 1px solid #ccc;
        padding: 15px 25px;
        font-size: 15px;
        background-color: #eee;
        position: relative;
        font-weight: bold;
        cursor: pointer;
        color: #515151;

        &:hover {
            background-color: #ddd;
        }

        i {
            position: absolute;
            right: 25px;
            height: 34px;
            top: 50%;
            margin-top: -17px;
            width: 34px;
            background: rgba(0, 0, 0, 0.055);
            border-radius: 100px;
            display: flex;
            color: #333333;
            align-items: center;
            justify-content: center;
        }
    }

    > .content {
        padding: 10px 25px 25px;
        background-color: rgb(199, 199, 199);
        overflow: hidden;
    }
}
