.DownloadFileComponent {
  .download-message {
    color: #666;
    padding: 25px;
    font-size: 18px;
    margin-top: 25px;
    border-left: 4px solid #ddd;
    margin-left: 25px;

    i {
      margin-right: 10px;
      font-size: 30px;
    }

    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: inline-block;
      max-width: 80%;
    }
  }

  .download-buttons {
    margin: 0 25px 25px 25px;
  }
}
