.DishComponent {
  .layout-class {
    justify-content: inherit !important;
    align-items: inherit !important;

    .canvas {
      display: flex;
      align-items: inherit;
      flex-direction: row;
      flex: 1;
      width: 100%;
      box-sizing: border-box;
      padding: 20px 20px 40px 20px !important;
      height: 100%;
      .cameras {
        align-items: inherit !important;
        flex: 1;
        width: 100%;
        height: 100%;
        .DishVideoComponent {
          flex: 1;
          width: auto !important;
          height: 100% !important;
          video {
            object-fit: cover !important;
          }
        }
      }
    }
  }

  .layout-focus {
    justify-content: inherit !important;
    align-items: inherit !important;
    .canvas {
      display: flex;
      align-items: inherit;
      flex-direction: row;
      flex: 1;
      width: 100%;
      padding: 20px 20px 20px 20px !important;
      box-sizing: border-box;
      height: 100%;
      .cameras {
        flex-direction: column;
        position: relative;
        align-items: flex-end !important;
        justify-content: flex-start !important;
        margin: 0px;
        flex-wrap: inherit !important;

        flex: 1;
        height: 100%;
        width: 100%;
        .DishVideoComponent {
          height: 200px !important;
          width: 280px !important;
          margin: 0 !important;
          margin-bottom: 20px !important;

          &.unselected {
            .SelfieDishComponent {
              transform: scale(0.8);
              top: 5px;
              left: 0;
            }
            video {
              object-fit: cover !important;
            }
            .remove {
              display: none;
            }

            .controls {
              display: none;
            }

            .selection {
              display: block !important;
              bottom: 4px;
              right: 2px;
              margin: unset;
            }
          }

          &.selected {
            margin: 0 !important;
            order: 0;
            position: absolute;
            height: inherit !important;
            top: 0px;
            width: calc(100% - 300px) !important;
            bottom: 0px;
            box-sizing: border-box;
            left: 0px;
            right: 300px;
            height: 100% !important;
          }
        }
      }
    }
  }
}

.ScenaryComponent {
  left: 0;
  top: 100px;
  bottom: 100px;
  right: 0;
  position: fixed;
  z-index: 1;
}

.DishVideoComponent {
}

.DishComponent.left {
  .ScenaryComponent {
    left: 430px;
  }
}

.DishComponent.bottom {
  .ScenaryComponent {
    bottom: 350px;
  }
}

.cameras {
  flex: 1 1;
  gap: 0px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  & .DishVideoComponent .DishVideoProfile {
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.2);
    animation: zoomIn 1s cubic-bezier(0.075, 0.82, 0.165, 1);
    @keyframes zoomIn {
      0% {
        transform: scale(0) translateY(100px);
      }
      100% {
        transform: scale(1);
      }
    }
  }

}

