@import '../../../variables.scss';

.UserInfoComponent {
  .userInfo {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: $comitas-blue-900;
    i {
      font-size: 30px;
      margin-left: 10px;
    }
  }

  .dropdown-menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    box-shadow: -2px 2px 18px 0 rgba(0, 0, 0, 0.1);

    animation: dropdown 0.5s;

    @keyframes dropdown {
      from {
        transform: translateY(-20%);
        opacity: 0;
      }
      to {
        transform: translateY(0%);
        opacity: 1;
      }
    }

    position: fixed;
    top: 100px;
    right: 0px;
    width: 250px;
    padding: 20px 20px;

    border-top: $comitas-blue-900 1px solid;

    .dropdown-item {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 10px;
      padding: 10px 20px;
      width: 90%;
      color: $comitas-blue-900;

      span {
        font-size: 14px;
      }

      &:hover {
        background-color: $comitas-blue-900;
        border-radius: 5px;
        color: white;
        cursor: pointer;
      }

      &.logout {
        color: red;

        &:hover {
          color: white;
        }
      }
    }
  }

  @media (max-width: 750px) {
    font-size: 14px;

    i {
      font-size: 20px;
    }
  }
}
