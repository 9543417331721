.StreamInfoComponent {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  // z-index: 10000;
  background-color: rgba(0, 0, 0, 0);

  .stream-info .box {
    padding: 20px;
    z-index: 1;
  }

  .stream-info .layout {
    overflow: auto;

    .divisor {
      padding: 15px;
      color: black;
    }

    .box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;

      .option {
        box-shadow: none;
        padding: 5px;
        border: 1px solid #f1f1f1;
        margin: 0;
        width: 90%;

        b {
          box-shadow: none;
          font-weight: normal;
        }
      }
    }
  }


}
