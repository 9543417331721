.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  z-index: 10000000000; // Asegúrate de que esté por encima de otros elementos
  .modal-content {
    background-color: white;
    padding: 30px;
    border-radius: 5px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    position: relative;
    width: 500px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 800px) {
      width: 80%;
    }

    .modal-content__icon {
      height: 100px;
      margin-top: -20px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .modal-content__info {
      h2 {
        margin-bottom: 15px;
        margin-top: 10px;
      }
    }

    &.success {
      background-color: #d4edda;
      border-color: #c3e6cb;
      color: #155724;
    }

    &.error {
      background-color: #f8d7da;
      border-color: #f5c6cb;
      color: #721c24;
    }

    &.warning {
      background-color: #fff3cd;
      border-color: #ffeeba;
      color: #856404;
    }

    .modal-content__buttons {
      display: flex;
      justify-content: center;
      gap: 10px;
      margin-top: 30px;

      button {
        padding: 10px 15px;
        border: 1px solid transparent;
        border-radius: 5px;
        cursor: pointer;
        font-size: 16px;
        width: 150px;
        transition: all 0.3s;

        &.cancel {
          background-color: rgb(219, 22, 27);
          color: white;

          &:hover {
            background-color: rgb(185, 18, 23);
          }
        }

        &.confirm {
          background-color: rgb(24, 61, 127);
          color: white;

          &:hover {
            background-color: rgb(20, 51, 106);
          }
        }

        &.close {
          background-color: #d3d3d3;
          color: #181818;

          &:hover {
            background-color: #b0b0b0;
          }
        }
      }
    }
  }

  p.content {
    font-weight: normal;
    color: #595958;
  }
}
