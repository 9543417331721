@import '../../../variables.scss';

.ShareFileComponent {
    p {
      margin-top: 15px;
      font-weight: bold;
      opacity: 0.5;
    }
    .Participants {
        margin: 0;
        padding: 0 20px;
        flex: none;
        overflow-y: auto;
    }
    .File {
      padding: 0 20px;
      margin: 0;

      input[type="file"] {
        display: none;
      }

      .fileUpload {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-top: 10px;

        .custom-file-upload {
          padding: 10px;
          cursor: pointer;
          background-color: $comitas-blue-900;
          border-radius: 4px;
          display: inline-block;
          text-align: center;
          width: 330px;

          &:hover {
            background-color: rgba(12, 59, 125, 0.848);
          }

          i {
            color: white;
            font-size: 30px;
          }
        }

        .originalFileName {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          display: inline-block;
          max-width: 100%;
          font-style: italic;
        }
      }
    }
}
