@import '../../../../../../../../../variables.scss';

.StepSliderComponent {
  background-color: white;
  border-radius: 4px;
  bottom: 90px;
  width: 300px;
  height: 100px;
  padding: 20px 20px 0 20px;

  i {
    font-size: 20px;
    color: white;
    font-weight: bold;
    margin-top: -5px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
    background-color: $comitas-blue-800;

    &[aria-disabled='true'] {
      background-color: lightgrey;
      cursor: not-allowed;
    }
  }

  p {
    margin-bottom: 8px;
  }

  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
  }

  .slider-labels {
    display: flex;
    justify-content: space-between;
    width: 263px;
    cursor: pointer;

    span {
      text-align: center;

      &.active {
        color: lightgray;
        font-weight: 600;
      }
    }
  }

  div {
    position: relative;
    width: 300px;
    height: 70px;
    top: 50%;
    left: 50%;
    margin: -50px 0 0 -135px;
  }

  $colorTrack: $comitas-blue-400 !default;
  $colorThumb: $comitas-blue-800 !default;

  $h: 1em;
  $w: 100%;

  @mixin trackN($n) {
    input[type='range'].n.n#{$n}:before {
      $bgW: $w / ($n - 1);
      background-size: (
        $bgW $h,
        $w .25em
      );
    }
  }

  @mixin track() {
    width: $w;
    box-sizing: border-box;
    height: $h;
    border-radius: $h/2;
    margin-right: 1em;
    box-sizing: content-box;
    background:
      (
        radial-gradient(circle at $h/2 50%, $colorTrack .3125em, $colorTrack .3125em, $colorTrack $h/2,transparent $h/2),
        linear-gradient(90deg, transparent $h, $colorTrack $h) repeat-x 0 50%
      );
  }

  @mixin thumb() {
    border: none;
    border-radius: 50%;
    box-sizing: border-box;
    border: (solid .35em $colorThumb) null (solid .25em $colorThumb);
    width: $h + .3em;
    height: $h + .3em;
    background: $colorThumb;
    background-clip: content-box;
    position: relative;
    z-index: 10;
    margin-top: -.1em;
    margin-left: .01em;
  }

  input[type='range'].n {

    display: block;
    margin: 1em auto;
    padding: 0;
    background: transparent;
    font-size: 1em;
    cursor: pointer;
    width: $w;
    position: relative;

    &,
    &::-webkit-slider-runnable-track,
    &::-webkit-slider-thumb {
      // -webkit-appearance: none;
    }
    &:after {
      // content: "";
      width: 1em;
      height: 1em;
      border-radius: 50%;
      background: $colorTrack;
      position: absolute;
      right: 0;
    }
    &:before {
      // content: "";
      position: absolute;
      z-index: 0;
      left: 0;
      right: $h - .2em;
      height: $h;
      background:
        (
          radial-gradient(circle at $h/2 50%, $colorTrack .3125em,  $colorTrack .3125em, $colorTrack $h/2,transparent $h/2),
          linear-gradient(90deg, transparent 0, $colorTrack 0) repeat-x 0 50%
        );
      box-sizing: border-box;
    }

    &::-ms-track {
      border: none;
      color: transparent;
    }
    &::-webkit-slider-thumb {
      @include thumb();
    }
    &::-moz-range-thumb {
      @include thumb();
    }
    &::-ms-thumb {
      @include thumb();
    }
    &::-ms-tooltip {
      display: none
    }
    &:focus {
      outline: none;
    }
  }

  @for $n from 2 through 10 {
    @include trackN($n);
  }
}
