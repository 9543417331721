@import "../../../variables.scss";

.LanguageSelectorComponent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;

  padding: 10px 0;

  width: 100px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  position: fixed;
  bottom: 105px;
  right: 10px;
  z-index: 100000;

  border: 1px solid $comitas-blue-900;

  &.on-room {
    right: 86px;
  }

  .language-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 80%;
    cursor: pointer;
    padding: 5px;
    border-radius: 10px;

    &:hover {
      background-color: $comitas-blue-900;
      color: #fff;
    }

    .flag-img {
      width: 30px;
      height: auto;
    }

    .language-code {
      text-transform: uppercase;
    }
  }
}
