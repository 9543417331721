@import "../../../variables.scss";

.ControlsComponent {

  &.help {
    z-index: 0;
  }

    flex-direction: row;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    background: $comitas-blue-900;
    // box-shadow: 0px 0px 100px rgba(0, 0, 0, 0.2);
    justify-content: center;
    height: 100px;
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 10000001000000;
    left: 0;
    overflow: hidden;
    animation: controlAnimation 1s cubic-bezier(0.075, 0.82, 0.165, 1);

    .ShareScreenModal {
      position: fixed;
      bottom: -290px;
      left: 50%;
      transform: translateX(-152%);
      width: 250px;
      background-color: #ffffff;
      border-radius: 10px;
      padding: 20px;
      box-shadow: 0px 0px 100px 10px rgba(0, 0, 0, 0.2);
      animation: translateToPosition 1s cubic-bezier(0.075, 0.82, 0.165, 1);

      @media all and (max-width: 920px) {
        left: 0;
        transform: translateX(0);
      }

      &.active {
        bottom: 90px;
      }

      @keyframes translateToPosition {
        0% {
          transform: translateX(-152%) translateY(100%);
        }

        100% {
          transform: translateX(-152%) translateY(0);
        }
      }

      @keyframes translateToPositionMobile {
        0% {
          transform: translateX(0) translateY(100%);
        }

        100% {
          transform: translateX(0) translateY(0);
        }
      }

      > div div {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        padding: 10px 15px;
        background-color: #fff;
        font-size: 17px;
        border-radius: 7px;
        color: #1b3f7c;

        i {
          margin-right: 20px;
          font-size: 30px;
        }

        &:hover {
          background-color: #1b3f7c;
          color: #fff;
          cursor: pointer;
        }

      }
    }

    @keyframes controlAnimation {
        0% {
            transform: translateY(100%);
        }

        100% {
            transform: translateY(0);
        }
    }

    .line {
        height: 1px;
        width: 1px;
        margin: 10px 0;
    }

    .logo + .line {
        margin-top: 0;
    }

    .logo {
        height: 70px;
        width: 70px;
        cursor: pointer;
        margin: 10px;
        padding: 4px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        display: none;

        &:hover {
            background: rgba(0, 0, 0, 0.174);
        }

        img {
            width: 100%;
        }

        .circle {
            height: 12px;
            width: 12px;
            border-radius: 20px;
            background-color: #fff;
            position: absolute;
            top: 10px;
            right: 10px;
            background: #ff4d4f;
            box-shadow: 0 0 0 1px #fff;

            &.true {
                background-color: #1a8529;
            }
        }
    }

    .buttons {
        display: flex;
        flex-direction: row;

        background: none;
        align-items: center;
        width: 100%;
        flex: 1;
        background-color: rgba(0, 0, 0, 0);
        padding: 15px;

        .space {
            flex: 1;
        }

        .first_group-buttons,
        .second_group-buttons,
        .third_group-buttons {
            display: flex;
            flex-direction: row;
            align-items: center;
            flex: 1;
            background-color: rgba(0, 0, 0, 0);
            padding: 15px;
        }

        .first_group-buttons {
            justify-content: flex-start;
        }

        .second_group-buttons {
            justify-content: center;
        }

        .third_group-buttons {
            justify-content: flex-end;
        }

        .button {
            margin: 5px 10px;

            height: 50px;
            width: 50px;
            font-size: 34px;
            border-radius: 10px;
            color: $comitas-blue-900;
            appearance: none;
            background: rgb(255, 255, 255);
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            position: relative;
            transition: all 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);

            animation: buttonAnimation 1s cubic-bezier(0.075, 0.82, 0.165, 1);

            @keyframes buttonAnimation {
                0% {
                    transform: translateY(100%) scale(0.7);
                }

                100% {
                    transform: translateY(0);
                }
            }

            sup {
                z-index: auto;
                min-width: 20px;
                height: 20px;
                padding: 0 6px;
                color: #000;
                font-weight: 400;
                font-size: 12px;
                line-height: 20px;
                white-space: nowrap;
                text-align: center;
                background: #fff;
                border-radius: 10px;
                box-shadow: 0 0 0 1px #fff;
                box-sizing: border-box;
                position: absolute;
                top: 5px;
                right: 5px;
                transform: translate(50%, -50%);
                transform-origin: 100% 0%;

                box &.red {
                    background: #ff4d4f;
                    color: #fff;
                }
            }

            &.active,
            &:hover {
                background-color: $comitas-blue-900;
                color: rgb(255, 255, 255);
                box-shadow: 0px 0px 0px 7px rgba(255, 255, 255, 0.07);

                .la-cog {
                    // rotate infinite
                    animation: spinEr 2s linear infinite;
                }

                sup {
                    background-color: black;
                    box-shadow: #000 0 0 0 1px;
                    color: #fff;
                }

                @keyframes spinEr {
                    0% {
                        transform: rotate(0deg);
                    }

                    100% {
                        transform: rotate(360deg);
                    }
                }
            }
        }

        .close {
            color: #ff4d4f;

            &:hover {
                color: rgb(255, 255, 255);
                background-color: #ff4d4f;
                box-shadow: 0px 0px 0px 7px rgba(255, 77, 79, 0.2);
            }
        }

        .exit {
            color: #ff4d4f;

            i.la-phone {
                margin-top: 5px;
                transform: rotate(135deg);
            }
        }
    }

    .status {
        position: fixed;
        bottom: -290px;
        left: 25;
        width: 250px;
        background-color: #ffffff;
        border-radius: 10px;
        padding: 10px;
        box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.1);
        transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1);
        border: 1px solid $comitas-blue-900;

        // &::after {
        //     height: 30px;
        //     width: 10px;
        //     background: #ffffff3c;
        //     position: absolute;
        //     bottom: -20px;
        //     left: 50%;
        //     display: block;
        //     content: "";
        //     border-radius: 0 0 10px 10px;
        // }

        &.active {
            bottom: 105px;
        }

        .message {
          font-size: 16px;
          text-align: center;
          color: $comitas-blue-900;
          padding: 10px 15px;
        }

        .mode {
            color: #fff;
            display: flex;
            align-items: center;
            padding: 10px 15px;
            font-size: 17px;
            position: relative;
            border-radius: 7px;
            cursor: pointer;
            color: $comitas-blue-900;
            align-items: center;
            display: flex;
            justify-content: center;
            .Internet {
                margin-left: auto;
                width: auto;
                padding: 6px 10px;
                margin-top: 0;
            }
            > b {
                border-radius: 5px;
                padding: 6px 10px;
                outline: none;
                text-align: center;
                font-size: 15px;
                font-weight: bold;
                text-transform: uppercase;
                letter-spacing: 1px;
                /* width: 100%; */
                box-sizing: border-box;
                border: 1px solid rgba(0, 0, 0, 0.144);
                color: white;
                margin-left: auto;
                width: auto;

                background-color: rgba(52, 76, 254, 0.848);
                font-size: 16px;
                cursor: pointer;
            }
            &.active {
                background-color: $comitas-blue-900;
                color: #fff;

                &::after {
                    opacity: 0;
                    height: 30px;
                    width: 5px;
                    position: absolute;
                    background-color: red;
                    content: "";
                    left: -20px;
                    top: 50%;
                    margin-top: -15px;
                    display: block;
                }
            }

            i {
                margin-right: 15px;
                font-size: 30px;
                opacity: 0.2;
            }
        }
    }

    .modes {
        position: fixed;
        bottom: -290px;
        right: 85px;
        width: 200px;
        background-color: #ffffff;
        border-radius: 10px;
        padding: 20px;
        box-shadow: 0px 0px 100px 10px rgba(0, 0, 0, 0.2);
        transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1);

        &.active {
            bottom: 90px;
        }

        .mode {
            color: #fff;
            display: flex;
            align-items: center;
            padding: 10px 15px;
            font-size: 17px;
            position: relative;
            border-radius: 7px;
            cursor: pointer;
            color: $comitas-blue-900;

            &.active {
                background-color: $comitas-blue-900;
                color: #fff;

                &::after {
                    opacity: 0;
                    height: 30px;
                    width: 5px;
                    position: absolute;
                    background-color: red;
                    content: "";
                    left: -20px;
                    top: 50%;
                    margin-top: -15px;
                    display: block;
                }
            }

            i {
                margin-right: 15px;
                font-size: 30px;
                opacity: 0.2;
            }
        }
    }
}
