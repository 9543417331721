
// Colors
$comitas-blue-50: #eff8ff;
$comitas-blue-100: #dbeefe;
$comitas-blue-200: #bfe2fe;
$comitas-blue-300: #93d1fd;
$comitas-blue-400: #60b6fa;
$comitas-blue-500: #3b97f6;
$comitas-blue-600: #2579eb;
$comitas-blue-700: #1d63d8;
$comitas-blue-800: #1e50af;
$comitas-blue-900: #1b3f7c; // Original
$comitas-blue-950: #172c54;

$comitas-green-50: #f0fff5;
$comitas-green-100: #d9fde3;
$comitas-green-200: #bafbd1;
$comitas-green-300: #8cf7b9;
$comitas-green-400: #51f0a2;
$comitas-green-500: #00e78e;
$comitas-green-600: #00c97e;
$comitas-green-700: #00a96d;
$comitas-green-800: #008a5a;
$comitas-green-900: #006b48;
$comitas-green-950: #004a2f;

