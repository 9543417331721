@import '../../../../../../../variables.scss';

.DishVideoRecording {
  border-radius: 10px;
  padding: 10px 20px;

  div {
    display: flex;
    align-items: center;
    justify-content: center;

    i {
      color: red;
      font-size: 15px;
      animation: blink 3s linear infinite;
      margin-right: 5px;
      background-color: white;
      border-radius: 50%;
      padding: 2px;
      border: 1px solid red;

      @keyframes blink {
        0% {
          opacity: 1;
        }

        50% {
          opacity: 0;
        }

        100% {
          opacity: 1;
        }
      }
    }

    i::before {
      background-color: red;
      border-radius: 50%;
    }

    p {
      font-size: 14px;
      color: $comitas-blue-900;
    }


  }

  @media (max-width: 750px) {
    padding: 5px;

    div {
      i {
        font-size: 13px;
      }
    }

    p {
      display: none;
    }
  }
}
