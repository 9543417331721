@import '../../../variables.scss';

.HelpView {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10000;
  z-index: 10000000000000000;
  overflow: auto;

  .HelpContainer {
    background-color: #fff;
    border-radius: 8px;
    width: 90%;
    max-height: 70vh;
    max-width: 70vw;
    overflow-y: auto;
    position: relative;

    .Header {
      position: sticky;
      top: 0;
      z-index: 10;
      display: flex;
      justify-content: space-between;
      padding-top: 10px;
      padding-bottom: 10px;

      .Title {
        font-size: 17px;
      }

      .Btn-container {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;

        .Close-Btn {
          padding: 10px;
          background-color: white;
          color: $comitas-blue-900;
          border: none;
          border-radius: 8px;
          cursor: pointer;
          font-size: 12px;
          text-align: center;

          &.circle {
            background: transparent;

            i {
              font-size: 20px;
              color: white;
            }
          }
        }
      }


    }

    .Body {
      padding: 20px;
      p {
        margin-bottom: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;

        img {
          max-width: 80%;
          height: 100%;
          border-radius: 8px;
        }
      }
    }
  }
}
