.ErrorsComponent {
    background-color: rgba(255, 255, 255, 0.605);
    color: #fff;
    border-radius: 4px;
    margin-bottom: 0px;
    scroll-margin: 150px;
    margin-top: 10px;
    box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 0;
    max-width: 350px;


    >li {
        display: flex;
        padding: 12px 12px 12px 15px;
        align-items: center;
        color: rgba(227, 65, 65, 0.749);
        font-size: 14px;
        font-weight: bold;

        >b {
            color: rgba(255, 255, 255, 0.604);

            padding: 2px 5px;
            border-radius: 4px;
            letter-spacing: 1px;
            font-size: 10px;

            margin-right: 10px;
            background: rgba(255, 255, 255, 0.1);
        }

        &:last-child {
            border-bottom: none;
        }
    }



}
