.LoginComponent {
    > .box {
        background-color: rgba(255, 255, 255, 0.48);
        padding: 45px;
        border-radius: 10px;
        position: fixed;
        top: 50px;
        right: 50px;
        bottom: 50px;
        overflow: hidden;
        box-shadow: 0px 0px 120px 11px rgba(0, 0, 0, 0.2);
        backdrop-filter: blur(10px);
        overflow-y: auto;
        z-index: 10000;

        &.loginForm {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
        }

        // phone version
        @media (max-width: 600px) {
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            border-radius: 0;
            padding: 20px;
        }

        h1 {
            color: rgb(0, 0, 0);
            margin: 0;
            padding: 0;
            width: 320px;
            font-size: 40px;
        }

        h2 {
            color: rgba(0, 0, 0, 0.6);
            margin: 0;
            padding: 0;
            font-weight: 300;
            width: 350px;
            font-size: 24px;
            margin-bottom: 20px;

            b {
              font-family: 'Rotis', sans-serif;
              font-weight: 900;
              font-size: 25px;
              letter-spacing: 1px;
              color: rgba(227, 28, 37, 1);
            }
        }

        .company {
            width: 170px;
            margin: 70px;
            position: absolute;
            z-index: 100000;
            bottom: 0;
            right: 0;
            filter: grayscale(1);
            opacity: 0.4;
            z-index: 1000000000;
        }
    }


    .microsoft {
      background-color: unset;
      color: black;
      border: none;
      border-radius: 5px;
      font-size: 16px;
      margin-top: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.3s;
    }

    .google {
      background-color: unset;
      color: #000;
      border: none;
      border-radius: 5px;
      font-size: 16px;
      margin-top: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.3s;
    }

    .video {
        position: fixed;
        top: 0;
        left: 0;
        right: 0px;
        bottom: 0;
        z-index: -1;

        video {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            z-index: -1;
        }
    }
}
