.DishComponent .bar {
    color: #000;
    padding: 0px;
    max-width:340px;
    min-width: 340px;
    max-width: 340px;
    min-width: 340px;
    background-color: rgba(255, 255, 255, 1);
    border-radius: 10px;
    position: relative;
    margin: 30px;
    flex: 1;
    overflow: hidden;

    z-index: 10;

    animation: DishComponentSlideRight 1s cubic-bezier(0.16, 1, 0.3, 1);


    @keyframes DishComponentSlideRight {
        from {
            transform: translateX(-400px);
        }

        to {
            transform: translateX(0%);
        }
    }

    .back {
        padding: 20px 30px;
        display: flex;
        align-items: center;
        cursor: pointer;
        background: rgba(255, 255, 255, 1);
        box-shadow: 0px 1px 50px rgba(0, 0, 0, 0.14);

        i {
            font-size: 25px;
            margin-right: 15px;
        }

        &:hover {
            background: rgb(129, 141, 255);
        }
    }







}