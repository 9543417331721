@import '../variables.scss';

.NickComponent {
    z-index: 999;
    position: absolute;
    left: 10px;
    top: 10px;
    margin: 0px;
    font-size: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: #fff;
    border-radius: 13px;
    padding: 10px 12px;

    strong {
        font-size: 14px;
        color: rgba($comitas-blue-950, 0.891);
        margin: 0;
        font-weight: bold;
        padding: 0;
        border-radius: 5px;
        text-shadow: 0px 1px 1px rgba(255, 255, 255, 0.78);
    }

    span {
        font-size: 12px;
        color: rgba($comitas-blue-950, 0.5);

        border-radius: 5px;
        text-shadow: 0px 1px 1px rgba(255, 255, 255, 0.78);
    }
}
