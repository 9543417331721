.ErrorComponent {
    background-color: #fff;
    padding: 30px;
    max-width: 350px;
    border-radius: 10px;
    z-index: 100000;

    >i {
        font-size: 60px;
        opacity: 0.2;
    }

    .title {
        font-size: 30px;
        color: rgb(206, 57, 57);
    }
}