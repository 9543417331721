.ClockComponent {
    position: fixed;
    bottom: 70px;
    left: 70px;

    .hour {
        font-size: 110px;
        font-weight: 400;
        font-family: Arial, Helvetica, sans-serif;
    }

    .day {
        font-size: 40px;
        font-weight: 300;
        opacity: 0.5;
    }

    @media (max-width: 600px) {
        display: none;
    }
}

.ClockBackgroundComponent {
    background-size: cover;
    background-position: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
