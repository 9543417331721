@import '../../../variables.scss';

.TimeComponent {
  color: $comitas-blue-900;

  .userInfo-date-hour {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;

    .userInfo-hour {
      font-size: 1.5rem;
      font-weight: 700;
      text-align: center;
    }

    .userInfo-date {
      font-size: 14px;
    }
  }

  @media (max-width: 750px) {
    font-size: 14px;

    .userInfo-date-hour {
      .userInfo-hour {
        font-size: 1.3rem;
      }

      .userInfo-date {
        font-size: 12px;
      }
    }
  }
}
