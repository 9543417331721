@import '../../../../../../../variables.scss';

.DishVideoProfile {
    /* Center */
    display: flex;
    justify-content: center;
    align-items: center;

    /* Size */
    width: 100%;
    height: 100%;

    &.FixedSize-300 {
        /* Size */
        width: 300px;
        height: 300px;
    }

    .title {
        /* Font */
        font-size: 1.5rem;
        font-weight: 600;
    }

    .title i {
        /* Icon bttom rigth */
        position: absolute;
        bottom: 0;
        right: 0;
        margin: 1rem;
    }
}
