.ScheduleComponent {
    h2 {
        padding: 0 20px;
        margin: 20px 0 0;
        font-size: 20px;
        display: flex;
        flex-direction: row;

        b {
            margin-left: auto;
            font-size: 14px;
            background: rgba(0, 0, 0, 0.1);
            padding: 5px 7px;
            border-radius: 5px;
        }
    }

    > ul {
        flex: 1;
        overflow-y: scroll;
        margin: 0;
        padding: 20px;

        > li {
            display: flex;
            list-style-type: none;
            text-align: left;
            padding: 10px 10px 10px 20px;
            border-radius: 5px;
            box-shadow: 0px 0px 51px rgba(0, 0, 0, 0.2);
            margin-bottom: 10px;
            align-items: center;

            > i {
                width: 40px;
                height: 40px;
                background-color: rgb(66, 172, 64);
                border-radius: 5px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 25px;
                color: white;
                margin-left: auto;
                margin-left: 10px;

                &.la-trash-alt {
                    background-color: rgb(159, 159, 159);
                }
            }

            .user {
                flex: 1;

                .name {
                    font-size: 16px;
                    font-weight: bold;
                }

                .document {
                    font-size: 15px;
                    opacity: 0.5;
                }
            }

            &.disabled {
                opacity: 0.5;
                cursor: not-allowed;
            }
        }
    }
}
