@import '../variables.scss';

.HelpComponent {
  overflow: auto;

  .Header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

  .Body {
    display: flex;
    flex-direction: column;
    align-items: center;

    height: 100%;
    overflow: hidden;

    .Input {
      width: 100%;
      margin-bottom: 20px;
      display: flex;
      justify-content: center;
      position: relative;
      margin-top: 0;

      input {
        padding-top: 8px;
        padding-bottom: 8px;
        font-size: 13px !important;

        &:focus {
          outline: none;
        }
      }

      .search-results,
      .no-results {
        position: absolute;
        top: 100%;
        left: 25px;
        width: 82%;
        background: white;
        border: 1px solid #ccc;
        list-style: none;
        padding: 10px;
        margin: 0;
        z-index: 1000;
      }

      .search-results {
        li {
          padding: 8px;
          cursor: pointer;

          &:last-child {
            border-bottom: none;
          }

          &:hover {
            background: #f0f0f0;
          }
        }
      }
      i {
        margin-left: 20px;
      }
    }

    .Content {
      padding: 0 20px 20px 20px;
      height: 100%;
      overflow: auto;
      font-size: 13px;

      display: flex;
      justify-content: center;

      .Sections {
        width: 100%;

        .Main-Section {
          border-radius: 5px;

          .Subsection-Header {
            opacity: 0.5;
            padding-top: 10px;
            font-size: 13px;
          }

          .Accordion-Header {
            padding: 10px;
            cursor: pointer;
            background-color: white;
            display: flex;
            justify-content: space-between;
            align-items: center;
            transition: background-color 0.3s ease;
            color: $comitas-blue-900;
            font-size: 14px;
          }

          .Accordion-Content {
            padding: 0 10px;
            max-height: 0;
            overflow: hidden;
            transition: max-height 0.5s ease, padding 0.3s ease;
            font-size: 15px;

            .Subsection:first-of-type {
              .Subsection-Header {
                padding-top: 0;
              }
            }

            > .Manual-List {
              padding-top: 0;
            }
          }

          &.active .Accordion-Content {
            max-height: 500px;
            overflow: auto;
            border-width: 1px;
          }
        }
      }

      img {
        max-width: 100%;
        height: auto;
      }

      ul ul ul li {
        list-style-type: circle;
      }

      .Manual-Info {
        h2 {
          margin-bottom: 20px;
          font-size: 14px;
          color: $comitas-blue-900;
          text-align: justify;
          hyphens: auto;
        }

        .Manual-Content {
          text-align: justify;
          hyphens: auto;

          *:not(img) {
            margin-bottom: 0.6em;
          }

          sup {
            line-height: 0;
          }

          table {
            width: 100% !important;
            height: auto !important;
          }
        }

        .BackToIndex {
          display: block;
          margin-bottom: 20px;
          cursor: pointer;
          font-size: 11px;
        }
      }

      .Section-List {
        padding: 0 10px;
        margin: 0;

        > li > .Manual-List {
          list-style: none;
        }

        > li > .Section-List {
          list-style: none;
        }
      }

      ol {
        list-style-type: none;
        counter-reset: item;
        margin: 0;
        padding: 0;
      }

      ol > li {
        display: table;
        counter-increment: item;
        // margin-bottom: 0.6em;
      }

      ol > li:before {
        content: counters(item, ".") ". ";
        display: table-cell;
        padding-right: 0.6em;
      }

      li ol > li {
        margin: 0;
      }

      li ol > li:before {
        content: counters(item, ".") " ";
      }
    }

    .Manual-List {
      cursor: pointer;
      list-style-type: none;
      text-align: justify;
      hyphens: auto;
      padding: 10px;
      margin: 0;
      li {
        font-size: 13px;
      }

      li:not(:last-child) {
        margin-bottom: 0.6em;
      }

      li:not(:first-child) {
        margin-top: 0.6em;
      }
    }

    h3 {
      margin-bottom: 0.6em;
      text-align: center;
    }
  }
}
