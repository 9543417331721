.SelectorComponent {
    background: rgba(0, 0, 0, 0.2);
    position: fixed;
    bottom: 100px;
    left: 0;
    right: 0;
    height: 250px;
    display: flex;
    flex-direction: row;
    padding: 10px;
    box-sizing: border-box;
    animation: slideUp 1s cubic-bezier(0.075, 0.82, 0.165, 1);
    justify-content: center;
    z-index: 100000000;

    @keyframes slideUp {
        0% {
            transform: translateY(100%);
        }

        100% {
            transform: translateX(0);
        }
    }

    .camera {
        display: flex;
        background-color: rgba(255, 255, 255, 1);
        width: 300px;
        border-radius: 10px;
        font-size: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 20px;
        overflow: hidden;
        position: relative;
        z-index: 1;

        .name {
            font-size: 20px;
            color: #000;
            position: absolute;
            left: 20px;
            top: 20px;
            font-weight: 600 !important;
            text-shadow: 0px 0px 2px rgba(253, 253, 253, 1), 0px 0px 2px rgba(253, 253, 253, 1),
                0px 0px 2px rgba(253, 253, 253, 1);
            overflow-wrap: break-word;
            // max-width: 140px;
        }

        .model {
            font-size: 24px !important;
            top: 45px;
            position: absolute;
            left: 20px;
            font-weight: bold;
            text-shadow: 0px 0px 2px rgba(253, 253, 253, 1), 0px 0px 2px rgba(253, 253, 253, 1),
                0px 0px 2px rgba(253, 253, 253, 1);
        }

        i {
            font-size: 30px;
            height: 50px;
            width: 50px;
            color: #fff;
            background-color: rgba(52, 76, 254, 0.848);
            border-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            left: 20px;
            bottom: 20px;
            outline: 4px solid rgba(0, 0, 0, 0.2);
        }

        .image {
            position: absolute;
            top: 2rem;
            right: .5rem;
            bottom: 0;
            left: auto;
            z-index: -1;
            background-position: center right;
            background-size: contain;
            background-repeat: no-repeat;
            width: 65%;
            video {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}
