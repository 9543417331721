.DishMutedWidget {
  background-color: #fff;
  border-radius: 10px;
  padding: 10px 20px;

  .DishVideoComponent.unselected &.focus {
    background-color: #fff;
    border-radius: 5px;
    padding: 5px 10px;

    // Align the icon to the left of the text
    left: 10px;
    right: auto;

    i {
      font-size: 15px;
      color: #D2151A;
    }

  }

  div {
    display: flex;
    align-items: center;
    justify-content: center;

    i {
      font-size: 20px;
      color: #D2151A;
    }
  }


}
