.ChatComponent {


  .messages {
    padding: 20px 35px;
    flex: 1;
    position: absolute;
    left: 0;
    bottom: 150px;
    right: 0;
    top: 90px;
    overflow-y: auto;
  }
}