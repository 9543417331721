@import '../../../variables.scss';

.ParticipantsComponent {
  .accordion {
    padding: 20px;
    overflow-y: auto;

    .accordion-item {
      border-radius: 5px;

      .accordion-header {
        padding: 15px 20px;
        cursor: pointer;
        background-color: white;
        display: flex;
        justify-content: space-between;
        align-items: center;
        transition: background-color 0.3s ease;
        color: $comitas-blue-900;
        font-size: 15px;
      }

      .accordion-content {
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.5s ease, padding 0.3s ease;
        font-size: 15px;

        .Input {
          display: flex;
          align-items: center;
          flex-flow: row;
          padding: 0 20px 10px 20px;
          margin-top: 0;

          i {
            margin-top: -25px;
            margin-left: 20px;
          }

          input {
            padding-top: 8px;
            padding-bottom: 8px;
            font-size: 13px !important;
          }
        }
      }

      &.active .accordion-content {
        max-height: 500px;
        overflow: auto;

        border-width: 1px;
      }
    }
  }

    ul {
        margin: 0;
        padding: 0 20px;
        flex: 1;
        overflow-y: auto;

        > li {
            display: block;
            list-style-type: none;
            text-align: left;
            padding: 10px 20px 0 10px;
            border-radius: 5px;
            // border: 1px solid #f1f1f1;
            display: flex;
            align-items: center;
            margin-bottom: 10px;

            &.NoUsers {
              text-align: center;
              border: none;
            }

            > span {
                height: 12px;
                width: 12px;
                border-radius: 100px;
                background-color: rgb(241, 89, 81);
                margin-left: auto;
                border: 4px solid rgb(223, 223, 223);
            }

            > i {
                width: 30px;
                height: 30px;
                background-color: rgb(66, 172, 64);
                border-radius: 5px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 25px;
                color: white;
                margin-right: 10px;

                &.la-smile {
                  background-color: $comitas-blue-900;
                }

                &.disconnected {
                  background-color: rgb(223, 223, 223);
                }

                &.end {
                  background-color: #e31c25;
                  &::before {
                    transform: rotate(135deg);
                    animation: phone-animation 0.5s infinite;
                  }
                }

                @keyframes phone-animation {
                  0% { transform: rotate(135deg); }
                  25% { transform: rotate(150deg); }
                  50% { transform: rotate(120deg); }
                  75% { transform: rotate(150deg); }
                  100% { transform: rotate(135deg); }
                }
            }

            .user {
            }

            .name {
                font-size: 14px;
            }

            .document {
                font-size: 12px;
                opacity: 0.5;
            }
        }
    }
}
