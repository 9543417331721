.LoaderComponent {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    z-index: 100000;
    opacity: 0;
    justify-content: center;
    pointer-events: none;
    transition: all 500ms cubic-bezier(0.075, 0.82, 0.165, 1);
    $green: #0057e7;
    $blue: #d62d20;
    $red: #0057e7;
    background-color: rgba(255, 255, 255, 0.699);
    $yellow: #d62d20;
    $width: 50px;

    .loader {
        position: relative;
        margin: 0 auto;
        width: $width;

        &:before {
            content: "";
            display: block;
            padding-top: 100%;
        }
    }

    .circular {
        animation: rotate 1s linear infinite;
        height: 80px;
        transform-origin: center center;
        width: 80px;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
    }

    .path {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
        animation: dash 1s ease-in-out infinite, color 6s ease-in-out infinite;
        stroke-linecap: round;
    }

    @keyframes rotate {
        100% {
            transform: rotate(360deg);
        }
    }

    @keyframes dash {
        0% {
            stroke-dasharray: 1, 200;
            stroke-dashoffset: 0;
        }
        50% {
            stroke-dasharray: 89, 200;
            stroke-dashoffset: -35px;
        }
        100% {
            stroke-dasharray: 89, 200;
            stroke-dashoffset: -124px;
        }
    }

    @keyframes color {
        100%,
        0% {
            stroke: $red;
        }
        40% {
            stroke: $blue;
        }
        66% {
            stroke: $red;
        }
        80%,
        90% {
            stroke: $blue;
        }
    }
    &.active {
        opacity: 1;
    }
}
