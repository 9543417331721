@import '../../../../../variables.scss';

.DishVideoComponent {
    height: 240px;
    background: rgba($comitas-blue-900, 0.4);
    border-radius: 10px;
    width: 300px;
    position: relative;
    overflow: hidden;
    // box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.2);
    animation: zoomIn 1s cubic-bezier(0.075, 0.82, 0.165, 1);

    @keyframes zoomIn {
        0% {
            transform: scale(0) translateY(100px);
        }

        100% {
            transform: scale(1);
        }
    }

    .HelpView {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background: rgba(0, 0, 0, 0.5);
      z-index: 10000;
      color: #fff;
      overflow: auto;

      .HelpContainer {
        background-color: #fff;
        border-radius: 8px;
        width: 90%;
        height: 80%;
        max-height: 70vh;
        max-width: 70vw;
        overflow-y: auto;
        position: relative;

        .Header {
          position: sticky;
          top: 0;
          z-index: 10;

          display: flex;
          justify-content: space-between;

          .Title {
            font-size: 17px;
          }

          i {
            font-size: 15px;
            margin-right: 0px;
            opacity: 1;
            cursor: pointer;
            border-radius: 100px;
            width: 50px;
            height: 50px;
            line-height: 20px;
            text-align: center;
            color: white;
            padding-top: 15px;
          }
        }

        .Body {
          p {
            text-align: center;
            img {
              max-width: 80%;
              height: 100%;
              border-radius: 8px;
            }
          }
        }

        .Footer {
          display: flex;
          justify-content: center;
          padding: 10px 0;
          position: sticky;
          bottom: 0;

          .Close-Btn {
            padding: 10px 20px;
            background-color: $comitas-blue-900;
            color: white;
            border: none;
            border-radius: 8px;
            cursor: pointer;
            font-size: 1rem;
            width: 100px;
            text-align: center;
          }
        }
      }
    }

    button {
        font-size: 34px;
        height: 65px;
        width: 65px;
        border-radius: 10px;
        background: rgba($comitas-blue-900, 1);
        border: none;
        margin: 8px;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &:hover {
            background: rgba($comitas-blue-900, 0.6);
        }
    }

    .render {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        border-radius: 10px;
        overflow: hidden;
        right: 0;
        z-index: 100;
    }

    .remove {
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 1000;
    }

    .info {
      position: relative;
      top: 10px;
    }

    .DishVideoRecording {
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 10000;
    }

    .DishMutedWidget {
      position: absolute;
      bottom: 10px;
      right: 10px;
      z-index: 999;
      height: 10px;
      width: 0px;
      display: flex;
      justify-content: center;
    }

    .settings {
        // background: #fff;
        // position: absolute;
        // z-index: 10200;
        // bottom: 20px;
        // overflow: hidden;
        // top: 20px;
        // box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.2);
        // width: 400px;
        // border-radius: 10px;
        // color: #000;
        // right: 20px;

        background: #fff;
        // position: absolute;
        z-index: 10200;
        bottom: 100px;
        overflow: hidden;
        top: 100px;
        width: 400px;
        /* border-radius: 10px; */
        color: #000;
        right: 0px;

        .infor {
            font-size: 14px;
            padding: 0 20px 0px;
            margin-left: 30px;
            position: relative;
            padding-left: 35px;
            margin-top: 0;

            i {
                font-size: 30px;
                position: absolute;
                left: -10px;
                top: 12px;
            }
        }
    }

    .selection {
        display: none;
    }

    .selection {
        position: absolute;
        bottom: 10px;
        transform: scale(0.8);
        right: 10px;
        z-index: 1000;
    }

    .name {
        background: rgba(0, 0, 0, 0.145);
        padding: 8px 13px;
        position: absolute;
        color: #fff;
        font-size: 12px;
        left: 0;
        border-top-right-radius: 5px;
        bottom: 0;
    }

    .controls {
        position: absolute;
        z-index: 1000;
        bottom: 10px;
        left: 0;
        right: 0;
        padding: 0px;
        display: flex;
        align-items: center;
        justify-content: center;

        &.loading {
            opacity: 0;
        }

        .selfie {
            border-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: auto;
            position: absolute;
            z-index: 1000;

            b {
                height: 50px;
                width: 50px;
                background-color: #fff;
                border-radius: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            span {
                margin-left: 10px;
                display: flex;
                flex-direction: column;
                color: #fff;

                em {
                    color: #fff;
                    background: #000;
                    padding: 2px 8px;
                    font-size: 13px;
                    align-self: flex-start;
                    border-radius: 100px;
                    border-radius: 100px;
                    font-style: normal;
                }
            }
        }
    }

    canvas {
        position: absolute;
        left: -20px;
        top: 0;
        bottom: 0;
        right: -20px;
        width: 110%;
        height: 100%;
    }

    .LoaderComponent {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        display: flex;
        flex: 1;
        height: 100%;
        z-index: 10000;
    }

    .render {
        &.loading {
            opacity: 0;
        }
    }

    video {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        object-position: center;
        pointer-events: none;
        transform: scaleX(-1);
        // filter: hue-rotate(-6deg) brightness(0.9) saturate(0.97);
        object-fit: contain;

        &.contain {
            object-fit: contain;
        }

        &.fill {
            // object-fit: fill;
        }

        &.invert {
            filter: invert(1);
        }

        &.coverSystemContain {
            object-fit: contain;
            position: relative;
            max-width: 100%;
            max-height: 100%;
        }
    }

    .flip video {
        transform: scaleX(1);
    }
}
