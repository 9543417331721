@import '../../../../../../../variables.scss';

.joystick {
  background-color: rgba($comitas-blue-900, 1);
  border-radius: 10px;
  padding: 30px 30px 0;
  font-size: 0;
  position: absolute;
  right: 20px;
  box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.2);
  bottom: 0px;
  .rang {
    margin-bottom: 20px;

    position: relative;
  }
  .decrease {
    height: 25px;
    width: 25px;
    background: rgba(255, 255, 255, 0.122);
    color: $comitas-blue-50;
    line-height: 25px;
    text-align: center;
    border-radius: 30px;
    position: absolute;
    left: -26px;
    top: 50%;
    margin-top: -12px;
    font-size: 15px;
    cursor: pointer;
  }
  .increase {
    height: 25px;
    width: 25px;
    background: rgba(255, 255, 255, 0.122);
    color: $comitas-blue-50;
    line-height: 25px;
    text-align: center;
    border-radius: 30px;
    position: absolute;
    right: -26px;
    top: 50%;
    margin-top: -12px;
    font-size: 15px;
    cursor: pointer;
  }
  > i {
    color: $comitas-blue-50;
    position: absolute;
    right: 0;
    top: 0;
    font-size: 20px;
    height: 50px;
    width: 50px;
    align-items: center;
    justify-content: center;
    display: flex;
    z-index: 10000;
  }

  .label {
    display: block;
    font-size: 14px;
    margin-bottom: 0.5rem;
    color: $comitas-blue-50;
    font-weight: 500;
    opacity: 0.9;
  }

  /* The slider itself */
  .slider {
    -webkit-appearance: none;
    /* Override default CSS styles */
    appearance: none;
    /* Full-width */
    height: 25px;
    border: none;
    /* Specified height */
    background: rgba(255, 255, 255, 0.164);
    /* Grey background */
    outline: none;
    /* Remove outline */
    opacity: 0.7;
    border-radius: 100px;

    /* Set transparency (for mouse-over effects on hover) */
    -webkit-transition: 0.2s;
    /* 0.2 seconds transition on hover */
    transition: opacity 0.2s;
  }

  /* Mouse-over effects */
  .slider:hover {
    opacity: 1;
    /* Fully shown on mouse-over */
  }

  /* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
  .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    /* Override default look */
    appearance: none;
    width: 25px;
    background: rgba(0, 0, 0, 0.367);
    box-shadow: 0px 5px 2px 0px rgba(0, 0, 0, 0.4);
    border-radius: 100px;
    border: 2px solid rgba(255, 255, 255, 0.337);
    /* Set a specific slider handle width */
    height: 25px;
    /* Slider handle height */
    /* Green background */
    cursor: pointer;
    /* Cursor on hover */
  }

  .slider::-moz-range-thumb {
    width: 25px;
    /* Set a specific slider handle width */
    height: 25px;
    /* Slider handle height */
    background: #04aa6d;
    /* Green background */
    cursor: pointer;
    /* Cursor on hover */
  }
}
