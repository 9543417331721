@import '../../../variables.scss';

.EditProfileComponent {
  .Header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    span {
      width: 100%;
      // text-align: center;
    }

    i.la-angle-left {
      margin-right: 10px;
    }
  }

  .Body-Options {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    margin-left: inherit;
    justify-content: flex-start;
    align-items: flex-start;

    div {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-flow: row nowrap;
      gap: 20px;
      font-size: 16px;
      font-weight: 500;
      padding: 15px;
      border-radius: 10px;
      width: 80%;

      i {
        font-size: 20px;
      }

      &:hover {
        cursor: pointer;
        background-color: $comitas-blue-900;
        color: white;
      }
    }
  }

  .Input i.la-angle-down {
    right: 10px;
    left: auto;
  }
}
