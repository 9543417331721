.MessageComponent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 10px;

  .details {
    padding: 0px 0 5px;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.5);
    display: flex;
    font-weight: bold;

    .date {
      margin-left: auto;
    }
  }

  &.me {
    align-items: flex-end;

    .message {
      background: rgb(44, 184, 44);
      border-radius: 20px 5px 20px 20px;
    }
  }

  .message {
    background-color: rgba(59, 66, 192, 0.917);
    padding: 10px 15px;
    color: #fff;
    border-radius: 5px 20px 20px 20px;
    font-size: 14px;
    font-weight: normal;
    word-wrap: break-word;
    max-width: 80%;
  }

  .date {
    display: block;
    font-weight: 400;
    font-size: 12px;
    opacity: 0.3;
  }
}
